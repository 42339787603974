<template>
    <section>
        <Header />
        <div class="container">
            <main class="principal">
                <picture class="only-pc aos">
                    <source srcset="../../assets/landing/background_principal_pc.webp" type="image/webp">
                    <img src="../../assets/landing/background_principal_pc.png" alt="Frame decorativo">
                </picture>
                <picture class="only-mov aos">
                    <source srcset="../../assets/landing/background_principal_mov.webp" type="image/webp">
                    <img src="../../assets/landing/background_principal_mov.png" alt="Frame decorativo">
                </picture>
                <h1 class="aos">Branding  <br> Agency <br> <span>in Chicago</span></h1>
                <p class="aos">
                    Legrafica is a digital marketing, development and design agency from Hermosillo, 
                    Sonora, with reach to all Mexico and USA.<br><br>
                    <strong>We create the perfect identity and personality for your brand</strong><br><br> 
                    Based by its three essential elements: purpose, values, and history.<br>
                    <br>
                    Our branding work focuses on creating a powerful visual image that establishes 
                    an effective communication line that emotionally connect with your desired audience. <br><br>
                    We design your logo, brand image, packaging, and more with our creative, dedicated, 
                    and responsible team.
                </p>
            </main>
            <section class="medio">
                <picture class="only-mov">
                    <source srcset="../../assets/landing/background_medio_naming.webp" type="image/webp">
                    <img src="../../assets/landing/background_medio_naming.png" alt="Imagen decorativa">
                </picture>
                <div class="aos">
                    <h2 class="medio__titulo">Branding Agency Chicago</h2> <br>

                    <ul>

                        <li>
                            <p><strong>Brand Identity: </strong> we develop a unique brand identity that reflects your 
                                values and purpose, ensuring an authentic connection with your audience.</p>
                        </li>
                        <li>
                            <p><strong>Logo Design Chicago: </strong>logos that capture the essence of your business and leave a 
                                lasting brand impression.</p>
                        </li>
                        <li>
                            <p><strong>Packaging Design: </strong>we create attractive and functional packaging that 
                                stands out in the market.</p>
                        </li>
                        <li>
                            <p><strong>Communication Strategy: </strong> establishing a clear and consistent communication
                                 strategy to empathize with your customers and strengthen your brand presence.
                            </p>
                        </li>
                    </ul>
                    <br>
                    <h2 class="medio__titulo">Why Choose Us?</h2> <br>
                    <ul>

                        <li>
                            <p><strong>Creativity: </strong> Innovating in every project to offer you unique 
                                and personalized solutions.</p>
                        </li>
                        <li>
                            <p><strong>Dedication: </strong> We fully commit to each client, ensuring exceptional 
                                results.</p>
                        </li>
                        <li>
                            <p><strong>Responsibility: </strong> Working with integrity and professionalism at 
                                every stage of the branding process.</p>
                        </li>
                    </ul>

                    <h3>Let's start working on your image. <br>
                        <a href="https://wa.link/6hddlt">Contact us and begin to stand out in the market!</a>
                    </h3> <br>


                </div>
            </section>
                <div class="medio_cuadricula aos">
                    <div>
                        <h2 class="medio__titulo">Naming: Creating Successful Business Names</h2> <br>
                        <p> 
                            It all starts with telling a story, similar to the ones we heard as children before bedtime. 
                            These stories, whether real or fictional, help us understand the essence and origin of your 
                            product or service. Through these narratives, we will find the words and phrases that bring 
                            to life the name that will immortalize your project.
                        </p><br class="only-pc"><br class="only-pc">
                    </div>
                    <div>
                        <h2 class="medio__titulo">Why is Naming so important?</h2>

                        <ul>
                            <li>
                                <p><strong>Brand Identity: </strong> a well-chosen name establishes a solid and memorable 
                                    identity for your business.</p>
                            </li>
                            <li>
                                <p><strong>Emotional Connection: </strong> the stories behind the name will help you create
                                    an emotional connection with your audience.</p>
                            </li>
                            <li>
                                <p><strong>Differentiation: </strong> a unique and meaningful name sets you apart from the 
                                    competition.</p>
                            </li>
                        </ul>
                        <br> 
                    </div>
                    <div>
                        <h2 class="medio__titulo">Meet our Naming process: </h2>

                    <ul>
                        <li>
                            <p><strong>Research and Understanding: </strong>We dive into your brand's history to 
                                understand its values, purpose, and vision.</p>
                        </li>
                        <li>
                            <p><strong>Story Creation: </strong>We develop narratives that capture the essence of 
                                your business.</p>
                        </li>
                        <li>
                            <p><strong>Name Selection: </strong> We identify potential names based on the stories, 
                                ensuring they are unique and relevant.</p>
                        </li>
                        <li>
                            <p><strong>Validation and Testing: </strong>We evaluate the selected names to ensure 
                                their impact and viability in the market.</p>
                        </li>
                    </ul>
                    <br>
                    </div>
                    <span>Transform your brand <br> with a name that tells its story!</span> <br><br><br>
                </div>
            <section class="proyectos">
                <h2 class="aos only-pc">Meet some of <br> our outstanding projects!</h2>
                <h2 class="aos only-mov ph2-mv">Meet some of  <span>our outstanding projects:</span></h2>
                <div class="proyectos__galeria">
                    <button class="aos" v-for="(project, index) in projects" :key="index"
                        @click="navAndDialog(project.link, project.imageWebp)" :aria-label="project.title">
                        <picture>
                            <source :srcset="project.imageWebp" type="image/webp">
                            <img :src="project.imagePng" :alt="project.title">
                        </picture>
                    </button>
                </div>
                <h2 class="aos">Let's work together, get a quote here.</h2>
                <p class="ultimoParrafo aos">Are you interested on renewing or develop your project with us?</p>

                <form class="aos formulario" @submit.prevent="contactoLanding()">
                    <div class="campoName">
                        <label for="form__name">Name</label>
                        <input type="text" id="form__name" v-model="name">
                    </div>
                    <div class="campoEmail">
                        <label for="form__email">Email</label>
                        <input type="email" id="form__email" v-model="email">
                    </div >
                    <div class="campoPhone">
                        <label for="form__phone">Phone Number</label>
                        <input type="number" v-model="phone">
                    </div>
                    <div class="campoMessage">
                        <label for="form__message">Message</label>
                        <textarea id="form__message" v-model="message"></textarea>
                    </div>
                    <div class="campoBtns">
                        <button type="submit">
                            <p>
                            Send Message
                        </p>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="only-pc">
                            <circle cx="24" cy="24" r="24" fill="#987EF3" />
                            <g clip-path="url(#clip0_137_399)">
                                <path
                                    d="M20.592 26.4954C20.4027 26.3414 20.2186 26.1848 20.0274 26.0399C18.3003 24.7329 16.5649 23.4382 14.8538 22.1116C14.6418 21.9478 14.5554 21.6188 14.4107 21.3669C14.6582 21.2636 14.9005 21.089 15.1558 21.0686C20.9931 20.5774 26.8324 20.0967 32.6719 19.6388C33.038 19.6102 33.4206 19.7784 33.796 19.855C33.6243 20.1794 33.5068 20.5536 33.273 20.8224C29.425 25.2399 25.5633 29.6462 21.6938 34.0434C21.5236 34.2358 21.2403 34.326 21.0093 34.4653C20.893 34.1991 20.6807 33.932 20.6759 33.6653C20.6347 31.5008 20.6337 29.3357 20.6171 27.1718C20.6153 26.9529 20.6011 26.7353 20.592 26.4954ZM31.0489 20.8108C31.0355 20.7584 31.0215 20.7081 31.0081 20.6558C26.0791 21.0602 21.1508 21.4659 16.2226 21.8716C17.6536 23.1375 19.115 24.2191 20.5455 25.3392C21.003 25.6977 21.3627 25.7561 21.8979 25.4782C24.7606 23.9967 27.6458 22.5629 30.523 21.1102C30.7038 21.0191 30.874 20.9103 31.0489 20.8108ZM31.4886 21.526C31.3506 21.4643 31.3252 21.4427 31.3123 21.4492C28.1515 23.03 24.99 24.6096 21.8352 26.2055C21.6905 26.2783 21.5217 26.4831 21.521 26.6295C21.5103 28.6332 21.5269 30.6395 21.5376 32.8818C24.9426 28.9959 28.1967 25.2828 31.4886 21.526Z"
                                    fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_137_399">
                                    <rect width="18" height="14.6667" fill="white"
                                        transform="translate(14.4043 21.3701) rotate(-26.7378)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </button>

                    <p class="form__status">{{ statusForm }}</p>
                    </div>
                </form>
            </section>
            <dialog @click="closeModal()">
                <img :src="imageSelected" alt="Imagen en galería">
            </dialog>
        </div>
        <Footer />
    </section>
</template>
<script>
import Header from '../../components/on-site/Header.vue';
import Footer from '../../components/on-site/Footer.vue';
export default {
    components: {
        Header,
        Footer,
    },

    data() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            statusForm: '',
            imageSelected: null,
            projects: [
                {
                    imageWebp: require('../../assets/landing/proyecto_7.webp'),
                    imagePng: require('../../assets/landing/proyecto_7.png'),
                    link: false,
                    title: "Musho Cevishe"
                },
                {
                    imageWebp: require('../../assets/landing/proyecto_8.webp'),
                    imagePng: require('../../assets/landing/proyecto_8.png'),
                    link: false,
                    title: "Holy O"
                },
                {
                    imageWebp: require('../../assets/landing/proyecto_9.webp'),
                    imagePng: require('../../assets/landing/proyecto_9.png'),
                    link: false,
                    title: "Mappy"
                },
                {
                    imageWebp: require('../../assets/landing/proyecto_10.webp'),
                    imagePng: require('../../assets/landing/proyecto_10.png'),
                    link: false,
                    title: "La Verbena"
                },
                {
                    imageWebp: require('../../assets/landing/proyecto_11.webp'),
                    imagePng: require('../../assets/landing/proyecto_11.png'),
                    link: false,
                    title: "Baby Mo"
                },
                {
                    imageWebp: require('../../assets/landing/proyecto_12.webp'),
                    imagePng: require('../../assets/landing/proyecto_12.png'),
                    link: false,
                    title: "Tribo"
                },
                {
                    imageWebp: require('../../assets/landing/proyecto_13.webp'),
                    imagePng: require('../../assets/landing/proyecto_13.png'),
                    link: false,
                    title: "Legatus"
                },
                {
                    imageWebp: require('../../assets/landing/proyecto_14.webp'),
                    imagePng: require('../../assets/landing/proyecto_14.png'),
                    link: false,
                    title: "Sweat & Wear"
                },
            ],
        }
    },

    mounted() {
        let animateOnScroll = () => {
            const allAos = document.querySelectorAll(".aos");

            let allHaveVisibleClass = true;

            allAos.forEach((elemento, index) => {
                let { top, height, bottom } = elemento.getBoundingClientRect();

                if (window.getComputedStyle(elemento).display === 'none'
                    || bottom > 0 && bottom < window.innerHeight
                    || height >= window.innerHeight && top <= 0) {
                    elemento.classList.add("visible");
                }

                if (!elemento.classList.contains("visible")) {
                    allHaveVisibleClass = false;
                }

            });

            if (allHaveVisibleClass) window.removeEventListener('scroll', animateOnScroll)

        }

        window.addEventListener('scroll', animateOnScroll);
        animateOnScroll()
    },

    methods: {
        async contactoLanding() {

            if (this.name === '' || this.name === null || this.name === undefined) {
                this.statusForm = "Campo Nombre no válido";
                return ''
            }

            if (this.email === '' || this.email === null || this.email === undefined) {
                this.statusForm = "Campo Correo Electrónico no válido";
                return ''
            }

            if (this.phone === '' || this.phone === null || this.phone === undefined) {
                this.statusForm = "Campo Teléfono no válido";
                return ''
            }

            if (this.message === '' || this.message === null || this.message === undefined) {
                this.statusForm = "Campo Mensaje no válido";
                return ''
            }

            let data = {
                name: this.name,
                phone: this.phone,
                email: this.email,
                mensaje: this.message,
                from: "landing naming & branding",
            };

            let response = await this.$store.dispatch(
                "admin/enviarMailContacto",
                data
            );

            if (response.status === 'success') {
                this.limpieza();
            } else {
                this.statusForm = "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
            }
        },

        limpieza() {
            this.name = '';
            this.phone = '';
            this.email = '';
            this.message = '';

            this.statusForm = 'Mensaje enviado con éxito';

            setTimeout(() => {
                this.statusForm = '';
            }, 3000);
        },

        navAndDialog(link, image) {
            if (link) {
                window.location.href = link;
            } else {
                this.imageSelected = image;
                this.openModal();
            }
        },

        openModal() {
            const modal = document.querySelector("dialog");
            modal.showModal();
        },

        closeModal() {
            const modal = document.querySelector("dialog");
            this.imageSelected = null;
            modal.close();
        }
    },

    metaInfo: {
        title: 'Branding Agency Chicago',  
        meta: [
            {
                name: 'description',
                content: 'We create your brand identity, logo, packaging design, communication strategy and a succesfull name for your project with the dedication and creativity that distinguishes us. '

            }
        ],
        htmlAttrs: {
            lang: 'en'
        }
    },

}
</script>
<style scoped>
@import "./stylesMain.css";
</style>